import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-7159b4dc"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "statistic"
};
const _hoisted_2 = {
  class: "title"
};
export default {
  __name: 'statistic',
  props: {
    title: {
      type: String,
      default: '更新日期'
    },
    value: {
      type: String,
      default: '2023-12-25'
    },
    size: {
      type: String,
      dafault: '20'
    }
  },

  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(__props.title), 1), _createElementVNode("div", {
        class: "value",
        style: _normalizeStyle({
          'font-size': __props.size + 'px'
        })
      }, _toDisplayString(__props.value), 5)]);
    };
  }

};